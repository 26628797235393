import { loadCSS, makeRequest } from '../util';

// When importing a HTML file, Parcel will make an asynchronous request to fetch it.
// This behavior cannot be changed in Parcel v1 (but should be possible in v2 see https://github.com/parcel-bundler/parcel/issues/1370)
// So we import the html file with fs.
import fs from 'fs';
const tooltipArrow = fs.readFileSync(__dirname + '/tooltip_arrow.html');

const TOOLTIP_WIDTH_NO_RECO_IN_PX = 240;
const TOOLTIP_WIDTH_WITH_RECO_IN_PX = 320;
const TOOLTIP_PADDING_IN_PX = 10;

// z-index is so high because of Bellerose
// We need to reset the browser default styles for the iframe, the arrow, and its children
const tooltipCSS = `
#ftl-tooltip {
 all: initial;
 position: fixed;
 z-index: 1000;
 padding: ${TOOLTIP_PADDING_IN_PX}px;
 background: white;
 box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06);
 border: solid 1px;
 display: none;
}
 
#ftl-tooltip-top-arrow {
 all: initial;
 position: absolute;
 transform: rotate(0deg);
 bottom: -4px;
 height: 8px;
 line-height: 1;
}
#ftl-tooltip-bottom-arrow {
 all: initial;
 position: absolute;
 transform: rotate(180deg);
 top: -4px;
 height: 8px;
 line-height: 1;
}
#ftl-tooltip-bottom-arrow > svg {
 all: initial;
}
#ftl-tooltip-top-arrow > svg {
 all: initial;
}
 
#ftl-tooltip-iframe {
 all: initial;
}
 
`;

loadCSS(tooltipCSS);

const TOOLTIP_TEXTS = {
  CN: '无需测量自己，即可发现您的理想尺寸及其合身度',
  DE: 'Entdecken Sie Ihre ideale Größe und Passform, ohne sich selbst messen zu müssen',
  EN: 'Discover your ideal size and its fit without having to measure yourself',
  ES: 'Descubre tu talla ideal y su ajuste sin tener que medirte',
  FI: 'Löydä ihanteellinen koko ja istuvuus mittaamatta itseäsi',
  FR: 'Découvrez votre taille idéale, sans avoir à vous mesurer',
  IT: 'Scopri la tua taglia ideale e la sua vestibilità senza dover misurarti',
  JP: 'お客様のボディサイズをご入力いただくと、適したサイズをご提案いたします。',
  NL: 'Ontdek uw ideale maat en pasvorm zonder uzelf te hoeven meten',
  NO: 'Oppdag din ideelle størrelse og dens passform uten å måtte måle deg selv',
  PT: 'Descubra o seu tamanho ideal e seu ajuste sem ter que se medir',
  SE: 'Upptäck din idealiska storlek och passform utan att behöva mäta dig själv',
  DA: 'Find ud af din ideelle størrelse, og hvordan den passer, uden at du behøver at måle dig selv',
  US: 'Discover your ideal size and its fit without having to measure yourself',
};

const TOOLTIP_CONTENT_STYLE = `
 body {
   font-family: 'Lato', sans-serif;
   text-align: center;
   font-size: 10px;
   line-height: 13px;
   margin:0;
   padding:0;
 }
 
 .ftl-loader {
   display: inline-block;
   position: relative;
   width: 80px;
   height: 80px;
 }
 .ftl-loader div {
   display: inline-block;
   position: absolute;
   left: 8px;
   width: 16px;
   background-color: grey;
   animation: ftl-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
 }
 .ftl-loader div:nth-child(1) {
   left: 8px;
   animation-delay: -0.24s;
 }
 .ftl-loader div:nth-child(2) {
   left: 32px;
   animation-delay: -0.12s;
 }
 .ftl-loader div:nth-child(3) {
   left: 56px;
   animation-delay: 0;
 }
 @keyframes ftl-loader {
   0% {
     top: 8px;
     height: 64px;
   }
   50%, 100% {
     top: 24px;
     height: 32px;
   }
 }
`;

const TOOLTIP_HEAD_INNER_HTML = `
   <link href="https://fonts.googleapis.com/css?family=Lato:400,700&display=swap" rel="stylesheet">
   <style>
     ${TOOLTIP_CONTENT_STYLE}
   </style>
`;

function resizeIframeToFitContent(iframe) {
  // We need to reset the dimensions when on single page app (such as Fitle demo)
  // Otherwise the scroll dimensions will stay at the previous higher values.
  iframe.style.height = '0px';
  iframe.style.width = '0px';
  iframe.style.height = `${iframe.contentDocument.body.scrollHeight}px`;
  iframe.style.width = `${iframe.contentDocument.body.scrollWidth}px`;
}

function setTooltipHTML(tooltipHTML) {
  try {
    const tooltipIframe = document.getElementById('ftl-tooltip-iframe');
    tooltipIframe.contentDocument.body.innerHTML = tooltipHTML;
    resizeIframeToFitContent(tooltipIframe);
  } catch (error) {
    window.Fitle.sendSentryBreadcrumb(`Couldn't find tooltip element in widget`);
    window.Fitle.sendSentryError(error);
  }
}

async function appendTooltipElement(widgetParameters) {
  return new Promise(resolve => {
    const tooltipElement = document.createElement('div');
    tooltipElement.id = 'ftl-tooltip';
    document.body.appendChild(tooltipElement);
    const arrowElement = document.createElement('div');
    arrowElement.innerHTML = tooltipArrow;
    if (widgetParameters.tooltipPosition && widgetParameters.tooltipPosition.includes('upper')) {
      arrowElement.id = 'ftl-tooltip-top-arrow';
    } else {
      arrowElement.id = 'ftl-tooltip-bottom-arrow';
    }
    tooltipElement.appendChild(arrowElement);
    const tooltipIframe = document.createElement('iframe');
    tooltipIframe.id = 'ftl-tooltip-iframe';
    tooltipIframe.src = 'about:blank';
    tooltipIframe.onload = () => {
      tooltipIframe.contentDocument.head.innerHTML = TOOLTIP_HEAD_INNER_HTML;
      setTooltipHTML(
        `<div style="width: ${TOOLTIP_WIDTH_NO_RECO_IN_PX - TOOLTIP_PADDING_IN_PX * 2}px">${
          TOOLTIP_TEXTS[widgetParameters.clientSiteLang]
        }</div>`,
      );
      resolve(tooltipElement);
    };
    tooltipElement.appendChild(tooltipIframe);
  });
}

function positionTooltip(widgetElement, tooltipElement, widgetParameters) {
  let arrowElement;
  if (widgetParameters.tooltipPosition && widgetParameters.tooltipPosition.includes('upper')) {
    arrowElement = document.getElementById('ftl-tooltip-top-arrow');
  } else {
    arrowElement = document.getElementById('ftl-tooltip-bottom-arrow');
  }
  const targetBoundingClientRect = widgetElement.getBoundingClientRect();
  // We add 5 px to get a bit of distance from the widget

  const tooltipWidth = tooltipElement.classList.contains('ftl-tooltip-no-reco')
    ? TOOLTIP_WIDTH_NO_RECO_IN_PX
    : TOOLTIP_WIDTH_WITH_RECO_IN_PX;
  if (widgetParameters.tooltipPosition === 'bottom') {
    tooltipElement.style.top = `${
      targetBoundingClientRect.top + targetBoundingClientRect.height + 5
    }px`;
    // Center the tooltip horizontally with regard to the widget
    tooltipElement.style.left = `${
      targetBoundingClientRect.left - (tooltipWidth - targetBoundingClientRect.width) / 2
    }px`;
    arrowElement.style.left = `${tooltipWidth / 2}px`;
  } else if (widgetParameters.tooltipPosition === 'bottom-right') {
    tooltipElement.style.top = `${
      targetBoundingClientRect.top + targetBoundingClientRect.height + 5
    }px`;
    tooltipElement.style.right = `${window.innerWidth - targetBoundingClientRect.right}px`;
    arrowElement.style.right = '20px';
  } else if (
    widgetParameters.tooltipPosition === 'bottom-left' ||
    // bottom-left is the default position as some clients have a bad integration where the widget is much larger
    // than its content. In this case, if you center the tooltip, it's ugly. With bottom-left, at least, it's obvious
    // than the tooltip comes from the widget
    !widgetParameters.tooltipPosition
  ) {
    tooltipElement.style.top = `${
      targetBoundingClientRect.top + targetBoundingClientRect.height + 5
    }px`;
    tooltipElement.style.left = `${targetBoundingClientRect.left}px`;
    arrowElement.style.left = '20px';
  } else if (
    widgetParameters.tooltipPosition === 'left'
  ) {
    tooltipElement.style.top = `${
      targetBoundingClientRect.top + targetBoundingClientRect.height + 5
    }px`;
    tooltipElement.style.left = `${targetBoundingClientRect.left - tooltipWidth/3 - 5}px`;
    arrowElement.style.left = '200px';
  }
  else if (widgetParameters.tooltipPosition === 'upper') {
    tooltipElement.style.bottom = `calc(100% - ${targetBoundingClientRect.top}px)`;
    // Center the tooltip horizontally with regard to the widget
    tooltipElement.style.left = `${
      targetBoundingClientRect.left - (tooltipWidth - targetBoundingClientRect.width) / 2
    }px`;
    arrowElement.style.left = `${tooltipWidth / 2}px`;
  } else if (widgetParameters.tooltipPosition === 'upper-right') {
    tooltipElement.style.bottom = `calc(100% - ${targetBoundingClientRect.top}px)`;
    // tooltipElement.style.top = `${
    //   targetBoundingClientRect.top + targetBoundingClientRect.height + 5
    // }px`;
    // Center the tooltip horizontally with regard to the widget
    tooltipElement.style.right = `${window.innerWidth - targetBoundingClientRect.right}px`;
    arrowElement.style.right = '20px';
  } else if (widgetParameters.tooltipPosition === 'upper-left') {
    tooltipElement.style.bottom = `calc(100% - ${targetBoundingClientRect.top}px)`;
    tooltipElement.style.left = `${targetBoundingClientRect.left}px`;
    arrowElement.style.left = '20px';
  } else {
    new Error(`Invalid value for tooltip position (${widgetParameters.tooltipPosition})`);
  }
}

let onMouseEnter, onMouseLeave, timer;
let hasReco = false;
export async function addTooltip(widgetElement, widgetParameters) {
  let tooltipElement = document.getElementById('ftl-tooltip');
  if (!tooltipElement) {
    tooltipElement = await appendTooltipElement(widgetParameters);
  } else {
    setTooltipHTML(
      `<div style="width: ${TOOLTIP_WIDTH_NO_RECO_IN_PX - TOOLTIP_PADDING_IN_PX * 2}px">${
        TOOLTIP_TEXTS[widgetParameters.clientSiteLang]
      }</div>`,
    );
  }
  tooltipElement.classList.remove('ftl-tooltip-with-reco');
  tooltipElement.classList.add('ftl-tooltip-no-reco');

  onMouseEnter = () => {
    positionTooltip(widgetElement, tooltipElement, widgetParameters);
    // Putting display block was making the tooltip taller than necessary (not sure why),
    // thus making the content not centered. display flex fixes that.
    tooltipElement.style.display = 'flex';
    // We have to resize the iframe when the tooltip is displayed
    // Otherwise, the iframe is not displayed and the scroll height will be 0
    resizeIframeToFitContent(document.getElementById('ftl-tooltip-iframe'));
    timer = window.setTimeout(() => {
      const { clientId, productId } = widgetParameters;
      const displayed = hasReco ? 'reco' : 'info';
      window.Fitle.sendEvent({
        event: 'tooltip_viewed',
        client_id: clientId,
        product_id: productId,
        displayed,
      });
      window.Fitle.sendMixpanelEvent({ __mixpanel_event__: 'tooltipViewed', displayed });
    }, 1000);
  };
  widgetElement.addEventListener('mouseenter', onMouseEnter);
  onMouseLeave = () => {
    tooltipElement.style.display = 'none';
    window.clearTimeout(timer);
  };
  widgetElement.addEventListener('mouseleave', onMouseLeave);
}

let onMouseOver;
let COMFORT_HTML;
async function getComfortHtml(sizes, gender, widgetParameters, comfortRendererTheme) {
  if (!COMFORT_HTML) {
    const comfortHtml = await makeRequest('POST', process.env.COMFORT_RENDERER_URL, {
      language: widgetParameters.clientSiteLang,
      sizes,
      gender,
      client_id: widgetParameters.clientId,
      body_part: widgetParameters.body_part,
      social_proof: widgetParameters.socialProof ? widgetParameters.socialProof : false,
      comfortRendererTheme,
    });
    COMFORT_HTML = comfortHtml;
    return COMFORT_HTML;
  } else {
    return COMFORT_HTML;
  }
}

async function setWidgetHoverCall(
  sizes,
  gender,
  widgetElement,
  widgetParameters,
  comfortRendererTheme,
) {
  // launch comfort in the background so it loads faster
  await getComfortHtml(sizes, gender, widgetParameters, comfortRendererTheme);
  onMouseOver = async () => {
    try {
      setTooltipHTML(
        `<div style="width:${TOOLTIP_WIDTH_WITH_RECO_IN_PX}px"><div class="ftl-loader"><div></div><div></div><div></div></div></div>`,
      );
      const comfortHTML = await getComfortHtml(
        sizes,
        gender,
        widgetParameters,
        comfortRendererTheme,
      );

      setTooltipHTML(comfortHTML);
    } catch (error) {
      // Hide tooltip if an error occurs
      document.getElementById('ftl-tooltip').style.display = 'none';
      window.Fitle.sendSentryError(error, {
        sizes,
        gender,
        widget_parameters: widgetParameters,
      });
    }
  };
  widgetElement.addEventListener('mouseover', onMouseOver, { once: true });
}

export function addRecoInTooltip(
  sizes,
  gender,
  widgetElement,
  widgetParameters,
  comfortRendererTheme,
) {
  const tooltipElement = document.getElementById('ftl-tooltip');
  if (tooltipElement) {
    tooltipElement.classList.remove('ftl-tooltip-no-reco');
    tooltipElement.classList.add('ftl-tooltip-with-reco');
    setWidgetHoverCall(sizes, gender, widgetElement, widgetParameters, comfortRendererTheme);
    hasReco = true;
  }
}

export function removeTooltip(widgetElement) {
  const tooltip = document.getElementById('ftl-tooltip');
  if (tooltip) {
    tooltip.parentNode.removeChild(tooltip);
    widgetElement.removeEventListener('mouseover', onMouseOver);
    widgetElement.removeEventListener('mouseenter', onMouseEnter);
    widgetElement.removeEventListener('mouseleave', onMouseLeave);
    // Per doc, clearing an invalid timeout does nothing
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/clearTimeout
    window.clearTimeout(timer);
  }
}
