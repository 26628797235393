// To support IE and avoid using a fetch polyfill but still having nice Promise syntax
export function makeRequest(method, url, data, withCredentials) {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    xhr.open(method, url);
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject(new Error(`Got error response ${method} ${url} ${xhr.status} ${xhr.statusText}`));
      }
    };
    xhr.onerror = function () {
      // Network level error https://stackoverflow.com/a/10584491
      // Will be ignored by Sentry thanks to "Network Error" in the error name
      reject(new Error(`Network Error: ${method} ${url}`));
    };
    if (withCredentials) {
      xhr.withCredentials = true;
    }
    if (data) {
      xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
      let json;
      try {
        json = JSON.stringify(data);
      } catch (e) {
        throw new Error(`[Fitle] Could not stringify data ${data}`);
      }
      xhr.send(json);
    } else {
      xhr.send();
    }
  });
}

export const donify = promise => {
  const done = cb => {
    const res = promise.then(cb);
    return donify(res);
  };
  const fail = cb => {
    const res = promise.catch(cb);
    return donify(res);
  };
  promise.done = done;
  promise.fail = fail;
  return promise;
};

// This function allows us to insert the style directly from the javascript code
// because importing CSS files with Parcel does not include the CSS in the javascript bundle
// It inserts a style element in the head. It has to be in the head and not the body,
// otherwise the client will not be able to override it.
export const loadCSS = css => {
  const style = document.createElement('style');
  style.type = 'text/css';
  style.appendChild(document.createTextNode(css));
  document.head.appendChild(style);
};

export const isIE = () => {
  // MSIE used to detect IE10 or older and Trident used to detect IE11
  return navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1;
};

export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    navigator.userAgent,
  );

// Per MDN, you have to compare origins to prevent cross-site scripting attacks
// https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#Notes
// We build the URL to be able to compare origins correctly.
// Otherwise you have problems with routes, trailing slashes and other.
export const isMessageFromSameOrigin = origin => {
  // NB: Hot reloading will mess document.referrer. So we do not check while in development.
  if (process.env.NODE_ENV === 'development') return true;

  // URL does not exist in IE so no security for them
  if (isIE()) return true;

  if (origin !== new URL(process.env.PLUGIN_URL).origin) {
    return false;
  } else {
    return true;
  }
};

export const getFirstXPromises = (promises, numberWanted) => {
  return new Promise((resolve, reject) => {
    let iterableCount = 0;

    const responses = {
      resolved: [],
      rejected: [],
    };

    if (promises.length === 0) {
      resolve(responses);
    }

    const resolveCheck = res => {
      iterableCount++;
      responses.resolved.push(res);
      if (responses.resolved.length >= numberWanted || iterableCount >= promises.length) {
        return resolve(responses);
      }
    };

    const handleRejection = res => {
      iterableCount++;
      responses.rejected.push(res);
      if (iterableCount >= promises.length) {
        if (responses.resolved.length > 0) {
          resolve(responses);
        } else {
          reject(responses);
        }
      }
    };

    // Launch all promises simultaneously, resolve when first 2 resolve.
    for (let i = 0; i < promises.length; i++) {
      promises[i].then(resolveCheck).catch(handleRejection);
    }
  });
};

export const isObjectEmpty = obj => obj && Object.keys(obj).length === 0;
export const isBoolean = obj => obj == true || obj == false;
