import { loadCSS } from '../util';

const iframeCSS = `
  #ftl-iframe {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    border: 0;
    top: 0;
    left: 0;
    /* Necessary because some clients (hum, Balzac, hum) like crazy high z-indexes */
    z-index: 999999999;
  }
`;

loadCSS(iframeCSS);

const IFRAME_SRC = process.env.PLUGIN_URL;

export function createIframe() {
  return new Promise(resolve => {
    const previousIframeElement = document.getElementById('ftl-iframe');
    let iframeElement;
    if (previousIframeElement !== null) {
      iframeElement = previousIframeElement;
      const iframe = new IFrame(iframeElement);
      if (iframeElement.getAttribute('data-status') === 'loaded') {
        resolve(iframe);
      } else {
        // Wait for iframe to be loaded before resolving
        // Useful for pages with several products
        const observer = new MutationObserver(mutations => {
          mutations.forEach(mutation => {
            if (
              mutation.attributeName === 'data-status' &&
              iframeElement.getAttribute('data-status') === 'loaded'
            ) {
              resolve(iframe);
              observer.disconnect();
            }
          });
        });
        observer.observe(iframeElement, { attributes: true });
      }
    } else {
      iframeElement = document.createElement('iframe');
      iframeElement.setAttribute('id', 'ftl-iframe');
      iframeElement.setAttribute('src', IFRAME_SRC);
      iframeElement.setAttribute('data-status', 'loading');
      iframeElement.addEventListener('load', () => {
        iframeElement.setAttribute('data-status', 'loaded');
        resolve(iframe);
      });

      const iframe = new IFrame(iframeElement);

      // This check is necessary because the sdk script might (in really weird cases) be loaded before a body is created.
      // For example we had this error on panamecollections.com as they use turbolink with rails.
      if (!document.body) {
        document.addEventListener('DOMContentLoaded', function onFitleDOMContentLoaded() {
          document.body.appendChild(iframeElement);
          document.removeEventListener('DOMContentLoaded', onFitleDOMContentLoaded);
        });
      } else {
        document.body.appendChild(iframeElement);
      }
    }
  });
}

class IFrame {
  constructor(iframeElement) {
    this.iframeElement = iframeElement;
  }
  open(parameters) {
    this.lastParameters = parameters;
    this.iframeElement.style.display = 'inline';
    this.post('FTL_OPEN_PLUGIN', parameters);
  }

  close() {
    this.post('FTL_CLOSE_PLUGIN');
    this.onPluginClosed();
  }
  reload() {
    // Used for hot reloading
    if (this.lastParameters) {
      this.post('FTL_OPEN_PLUGIN', this.lastParameters);
    }
  }

  onPluginClosed() {
    this.iframeElement.style.display = 'none';
  }

  post(event, payload) {
    const message = { event, payload };
    // Per MDN, you should always specify a target origin to avoid disclosing data to malicious sites.
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
    // Here the target origin is the iframe.
    this.iframeElement.contentWindow.postMessage(message, process.env.PLUGIN_URL);
  }
}
