import { loadCSS, isMessageFromSameOrigin, isIE, isMobile, makeRequest } from '../util';
import { parseLanguage, WIDGET_TEXTS, validateAvailableSizes, checkUnitSystem } from './validation';
import { addTooltip, removeTooltip, addRecoInTooltip } from './tooltip';

const widgetCSS = `
 .ftl-widget {
   cursor: pointer;
   position: relative;
 }
 
 .ftl-widget-logo,
 .ftl-widget-text {
   display: inline-block;
   vertical-align: middle;
 }
 
 .ftl-widget-logo {
   width: 2em;
   height: 2em;
   background-image: url('${process.env.REACT_APP_STATIC_URL}/ruler-icon.svg');
   background-size: contain;
   background-repeat: no-repeat;
   background-position: center;
 }
 
 .ftl-widget-text {
   margin-left: 0.5em;
 }
 
 .ftl-widget-logo-with-reco {
 }
 
 .ftl-widget-reco-size {
   font-weight: bold;
 }
 `;

loadCSS(widgetCSS);

const PROPERTY_MAPPING = {
  client_id: 'clientId',
  product_id: 'productId',
  product_image: 'productImageUrl',
  language: 'clientSiteLang',
  unit_system: 'clientUnitSystem',
  end_behavior: 'endBehavior',
  available_sizes: 'availableSizesFromClient',
  brand_type_id: 'brand_type_id',
  sizes: 'productSizes',
  body_part: 'body_part',
  gender: 'gender',
  shoe_shape_slug: 'shoeShapeSlug',
  sizing_chart_slug: 'sizingChartSlug',
  text: 'text',
  widget_id: 'widgetId',
  measurement_names: 'measurementNames',
  shop_country: 'shop_country',
  tooltip_position: 'tooltipPosition',
  bra_input_mandatory: 'isBraInputMandatory',
  social_proof: 'socialProof',
  has_references_step: 'has_references_step',
  client_hostname: 'clientHostName',
};

const RECOMMENDED_SIZE_TEXTS = {
  CN: '您的理想尺寸：',
  DE: 'Ihre ideale Größe:',
  EN: 'Your ideal size:',
  ES: 'Su talla ideal :',
  FI: 'Ihanteellinen koko:',
  FR: 'Votre taille idéale :',
  IT: 'La tua taglia ideale :',
  JP: 'あなたの理想的なサイズ：',
  NL: 'Uw ideale maat:',
  NO: 'Din ideelle størrelse:',
  PT: 'Seu tamanho ideal :',
  SE: 'Din idealiska storlek:',
  DA: 'Din ideelle størrelse :',
  US: 'Your ideal size:',
};

export class Widget {
  constructor(parameters, targetElement, iframe, customization, comfortRendererTheme) {
    if (!(targetElement && targetElement.tagName)) {
      console.warn('[FITLE] Widget received invalid target element', targetElement);
      throw new Error(`Target element is invalid`);
    }
    this.parameters = { widgetId: Math.ceil(Math.random() * (100000000 - 1) + 1) };
    Object.keys(parameters).forEach(k => {
      this.set(k, parameters[k], true);
    });
    this.iframe = iframe;
    this.targetElement = targetElement;
    this.customization = customization || {};
    this.currentRecommendedSize = null;
    this.comfortRendererTheme = comfortRendererTheme || {};
    this.handleWindowMessage = this.handleWindowMessage.bind(this);
    this.handleWidgetClick = this.handleWidgetClick.bind(this);
  }

  // Remember to update deactivate upon modifying that function.
  activate() {
    this.targetElement.setAttribute('data-widget-id', this.parameters.widgetId);
    this.targetElement.addEventListener('click', this.handleWidgetClick);
    this.appendWidgetElement();
    // plugin's frame and host page communicate through event `message` (postMessage)
    window.addEventListener('message', this.handleWindowMessage);
    if (this.customization.auto_reco) {
      this.getAutoReco();
    }
  }

  // Undo everything activate does.
  deactivate() {
    this.targetElement.removeAttribute('data-widget-id');
    this.targetElement.removeEventListener('click', this.handleWidgetClick);

    this.targetElement.classList.remove('ftl-widget');
    this.targetElement.classList.remove('ftl-reco-button');

    const iconElement = this.targetElement.querySelector('span.ftl-widget-logo');
    iconElement.parentNode.removeChild(iconElement);
    const textElement = this.targetElement.querySelector('span.ftl-widget-text');
    textElement.parentNode.removeChild(textElement);

    window.removeEventListener('message', this.handleWindowMessage);

    removeTooltip(this.targetElement);
  }

  handleWidgetClick() {
    const timestamp_ms = Date.now();
    window.Fitle.sendEvent({
      event: 'widget_click',
      timestamp_ms,
      client_id: this.parameters.clientId,
      product_id: this.parameters.productId,
    });
    this.parameters.__clickTimeMs = timestamp_ms;
    this.iframe.open(this.parameters);
  }

  getWidgetText(language, recommendedSize) {
    if (recommendedSize) {
      if (recommendedSize.found) {
        // We don't support custom text for reco at the moment
        return `${RECOMMENDED_SIZE_TEXTS[language]} <span class="ftl-widget-reco-size">${recommendedSize.name}</span>`;
      }
      // If no reco found, we return the standard text because showing a text like "No right size for you"
      // might be a bit harsh
    }
    if (this.parameters.text && this.parameters.text[language]) {
      return this.parameters.text[language];
    }
    return WIDGET_TEXTS[language];
  }

  setWidgetText(language, recommendedSize) {
    try {
      const textElement = this.targetElement.getElementsByClassName('ftl-widget-text')[0];
      const text = this.getWidgetText(language, recommendedSize);
      textElement.innerHTML = text;
    } catch (error) {
      window.Fitle.sendSentryBreadcrumb(`Couldn't find text element in widget`);
      window.Fitle.sendSentryError(error);
    }
  }

  appendWidgetElement() {
    this.targetElement.classList.add('ftl-widget');
    // Backward comp (Morvelo)
    this.targetElement.classList.add('ftl-reco-button');
    // We don't have a specification for shoes tooltip at the moment
    // We show the tooltip only for adults
    // We don't show the tooltip on touchable devices (duh)
    // IE doesn't support comfort comparison SVG
    if (
      this.customization.show_tooltip &&
      !(
        this.parameters.body_part === 'feet' ||
        ['male', 'female', 'unisex'].indexOf(this.parameters.gender) === -1 ||
        isIE() ||
        isMobile()
      )
    ) {
      addTooltip(this.targetElement, this.parameters);
    }

    const iconElement = document.createElement('span');
    iconElement.classList.add('ftl-widget-logo');
    this.targetElement.appendChild(iconElement);

    const textElement = document.createElement('span');
    textElement.classList.add('ftl-widget-text');
    this.targetElement.appendChild(textElement);
    this.setWidgetText(this.parameters.clientSiteLang || 'EN');
  }

  addRecoInWidget({ found, name, sizes, user_gender }) {
    if (found) {
      this.targetElement.classList.add('ftl-widget-with-reco');

      // Some clients remove the fitle logo
      if (this.targetElement.getElementsByClassName('ftl-widget-logo').length > 0) {
        const iconElement = this.targetElement.getElementsByClassName('ftl-widget-logo')[0];
        iconElement.classList.add('ftl-widget-logo-with-reco');
      }

      this.setWidgetText(this.parameters.clientSiteLang, { found, name });
      addRecoInTooltip(
        sizes,
        user_gender,
        this.targetElement,
        this.parameters,
        this.comfortRendererTheme,
      );
    }
  }

  // Unused so far see comment line 402.
  selectSizeAutoReco(found, name) {
    if (
      found &&
      this.parameters.endBehavior === 'select-size' && // client has set behavior to "select-size"
      this.onSizeSelectedCallback // client has set on recommendation callback
    ) {
      // If we have availableSizes we check that the auto_reco size is available and use it to enrich the size object.
      if (
        this.parameters.availableSizes !== undefined &&
        this.parameters.availableSizes.some(size => size.name === name)
      ) {
        let validatedSize = JSON.parse(
          JSON.stringify(this.parameters.availableSizes.find(size => size.name === name)),
        );
        validatedSize.found = true; // For backward compatibility (Balzac)
        this.onSizeSelectedCallback(validatedSize);
      }
      // else if no availableSizes we just send an object with the size name
      else if (this.parameters.availableSizes === undefined) {
        this.onSizeSelectedCallback({ name });
      }
    }
  }

  handleWindowMessage(e) {
    let parsed;
    try {
      parsed = this._parseFitleMessage(e);
      if (!parsed) return null;
      const { event, payload, widgetId } = parsed;
      return this._handleFitleEvent(event, payload, widgetId);
    } catch (error) {
      window.Fitle.sendSentryError(new Error(`Error handling message: ${error.message}`), {
        parsedMessage: parsed,
      });
    }
  }

  _parseFitleMessage(messageEvent) {
    try {
      const { data, origin } = messageEvent;
      if (!isMessageFromSameOrigin(origin)) return null;

      const { from, event, payload, widgetId } = data;
      if (from !== 'FTL_MESSAGE') return null;

      return { event, payload, widgetId };
    } catch (e) {
      console.error('[FITLE] error', e);
      // it might be a message from react devtools (in development)
      return null;
    }
  }

  async _handleFitleEvent(event, payload, incomingWidgetId) {
    if (event === 'RELOAD' && process.env.NODE_ENV !== 'production') {
      // Used for hot reloading
      return this.iframe.reload();
    }
    // All widgets should handle some plugin messages globally (for example to auto fetch reco when physical info are set)
    if (event === 'RECO_FETCHED') {
      const eventWidgetId = payload.is_auto_reco ? payload.widgetId : incomingWidgetId;
      this.onRecommandationFetched(payload, this.parameters.widgetId === eventWidgetId);
    } else if (this.parameters.widgetId === incomingWidgetId) {
      switch (event) {
        case 'CLOSED':
          return this.onClose();
        case 'SIZE_SELECTED':
          return this.onSizeSelected(payload);
        default:
          console.warn(`[Fitle] Got unknown event from plugin ${event} with payload ${payload}`);
      }
    }
  }

  // Deprecated. Allowing users to set properties dynamically makes things more complex (validation, values set to late...)
  // for not a lot of value.
  // Used by a lot of clients so the code will stay but should not be used any more.
  set(key, value, initializing) {
    if (key === 'language') {
      const language = parseLanguage(value);
      this.parameters.clientSiteLang = language;
      if (!initializing) this.setWidgetText(language);
    } else if (key === 'text') {
      this.parameters.text = {};
      for (let language in value) {
        const languageCode = parseLanguage(language);
        this.parameters.text[languageCode] = value[language];
      }
      if (!initializing) this.setWidgetText(this.parameters.clientSiteLang);
    } else if (key === 'unit_system') {
      if (value) {
        value = checkUnitSystem(value);
        this.parameters.clientUnitSystem = value;
      }
    } else if (key === 'available_sizes') {
      if (!initializing) {
        const availableSizesValidity = validateAvailableSizes(value, this.parameters.productSizes);
        if (availableSizesValidity === 'INVALID') {
          if (!this.parameters.social_proof) {
            makeRequest(
              'PUT',
              `${process.env.API_URL}/wrong_sizing_chart_products?client_id=${this.parameters.clientId}&client_product_id=${this.parameters.productId}`,
            );
          }
          // Available sizes are invalid, delete the widget
          this.deactivate();
        } else if (availableSizesValidity === 'VALID') {
          this.parameters.availableSizes = value;
        }
      } else {
        this.parameters.availableSizes = value;
      }
    } else {
      const mapped = PROPERTY_MAPPING[key];
      if (mapped) {
        this.parameters[mapped] = value;
      } else {
        console.error(
          `[Fitle] Unknown parameter "${key}". Parameters must be one of ${Object.keys(
            PROPERTY_MAPPING,
          ).join(', ')}`,
        );
      }
    }
    return this;
  }

  // For backward compatibility (LeazBoutique, Bellerose)
  availableSizes(sizes) {
    if (typeof sizes === 'function') sizes = sizes(); //Bellerose
    this.set('available_sizes', sizes);
    return this;
  }

  // For backward compatibility (LeazBoutique)
  addCart(size) {
    // Used for analytics
    return this;
  }

  on(event, callback) {
    switch (event) {
      case 'validate':
      case 'recommendation': {
        // Add recommendation for backward comp (Balzac)
        this.onSizeSelectedCallback = callback;
        break;
      }
      // For backward comp (Bellerose)
      case 'ready': {
        // We call the callback right away as the plugin is ready by now
        // In fact the widget is only created after the iframe has been loaded (with the plugin code inside the iframe)
        callback(this.targetElement);
        break;
      }
      case 'recommendation-fetch': {
        this.onRecommandationFetchedCallback = callback;
        break;
      }
      case 'close': {
        this.onCloseCallback = callback;
        break;
      }
      default: {
        throw new Error('Unknown event');
      }
    }
    return this;
  }

  onSizeSelected({ name }) {
    if (this.onSizeSelectedCallback) {
      let validatedSize = { name };

      // If we have availableSizes we use it to enrich the size object.
      if (Array.isArray(this.parameters.availableSizes)) {
        validatedSize = JSON.parse(
          JSON.stringify(this.parameters.availableSizes.find(size => size.name === name)),
        );
        validatedSize.found = true; // For backward compatibility (Balzac)
      }

      this.onSizeSelectedCallback(validatedSize);
    }
  }

  onRecommandationFetched(payload, recoFetchedForThisWidget) {
    const { name, found, is_auto_reco, sizes, user_gender } = payload;
    if (recoFetchedForThisWidget) {
      if (this.customization.auto_reco) {
        this.addRecoInWidget({ name, found, sizes, user_gender });
        // Selecting the size on auto-reco breaks some client website when on those websites selecting the size refreshes the page!
        // To fix this the best course of action would be to specify in the widget parameters if we activate or not the select size on auto-reco.
        // this.selectSizeAutoReco(found, name);
      }
      if (this.onRecommandationFetchedCallback) {
        // let recommendedSize = {};
        // if (this.parameters.availableSizesFromClient) {
        //   recommendedSize = this.parameters.availableSizesFromClient.find(
        //     size => size.name === name,
        //   );
        // }
        this.onRecommandationFetchedCallback({
          recommended_size: name,
          found,
          is_auto_reco,
        });
      }
    } else if (this.customization.auto_reco && !is_auto_reco) {
      // This way, all plugins on the page will get a reco, once a reco has been done in the plugin
      // and thus the necessary info for the reco is in the store
      this.getAutoReco();
    }
  }

  onClose() {
    this.iframe.onPluginClosed();
    if (this.onCloseCallback) {
      this.onCloseCallback();
    }
  }

  getAutoReco() {
    const { widgetId, clientId, productId, sizingChartSlug, body_part, gender, socialProof } =
      this.parameters;
    this.iframe.post('FTL_GET_AUTO_RECO', {
      widgetId,
      sizingChartSlug,
      body_part,
      gender,
      clientId,
      productId,
      socialProof,
    });
  }
}
